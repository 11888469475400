
@keyframes blink {
  0% { height: 0;}
  9% { height: 0;}
  10% { height: 70%;}
  11% { height: 0;}
  29% { height: 0;}
  30% { height: 30%;}
  31% { height: 0;}
  39% { height: 0;}
  40% { height: 60%;}
  41% { height: 0;}
  49% { height: 0;}
  50% { height: 50;}
  51% { height: 0;}
  69% { height: 0;}
  70% { height: 30%;}
  71% { height: 0;}
  89% { height: 0;}
  90% { height: 40%;}
  91% { height: 0;}
  95% { height: 0;}
  96% { height: 70%;}
  97% { height: 0;}
  100% { height: 0;}
}

.iris {
  position: absolute;
  top: 2.4px;
  left: 2.4px;
}

.eyeIsCenter {
  animation: lookCenter 0.2s 1;
  animation-timing-function: ease-in-out;
  top: 0;
  left: 0;
}

@keyframes lookCenter {
  0% { top:2.4px; left:2.4px;}
  100% { top:0px; left:0px;}
}