.waving-hand {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(14deg);
  }
  30% {
    transform: rotate(-8deg);
  }
  40% {
    transform: rotate(14deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  60% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes shakeme {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(0.7);
  }
  10% {
    transform:scale(1.5);
  }
  15% {
    transform:scale(1.1);
  }
  20% {
    transform:scale(1.4);
  }
  30% {
    transform:scale(1.1);
  }
}
